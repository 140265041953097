import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';
import Estimate from 'components/Estimate';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Radio } from 'components/Form/Radio';
import { Select } from 'components/Form/Select';
import Spacer from 'components/Spacer';
import {
  YearDropdown,
  withEventYearFilterContext,
} from 'components/YearDropdown';
import { MemberDropdown } from 'pages/app/Notifications/Edit/MemberDropdown';
import { OpenCreditDropdown } from 'pages/app/Notifications/Edit/OpenCreditDropdown';
import { AppContext } from 'shared/AppContext';
import CreateTextMessage from '../gql/mutations/create-text-message';
import GetNotificationEstimate from '../gql/mutations/get-estimate';
import { useGetNotificationEvents } from '../gql/queries/get-user-notification-events';
import GuestOptions from './GuestOptions';
import TextForm from './TextForm';
import TextListData from './TextListData';

export const ComposeButton = styled.button`
  border-width: 3px;
  border-style: solid;
  border-color: #fa4616;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  color: #fff;
  font-size: 22px;
  font-family: 'Barlow Condensed';
  background-color: #fa4616;
  width: 100%;
  display: block;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  ${(props) =>
    props.disabled
      ? `border-width: 3px;
  border-style: solid;
  border-color: #ccc; background-color: #ccc; pointer-events: none`
      : ''};

  &:hover {
    background: rgba(250, 70, 22, 0.9) !important;
  }
`;

const textTargets = [
  {
    label: '',
    value: '__please_select__', //! dont change this valu
  },
  {
    label: 'App Users',
    value: 'users',
  },
  {
    label: 'Guests',
    value: 'guests',
  },
  {
    label: 'Opt In Text Lists',
    value: 'lists',
  },
];

const TextMessageForm = withEventYearFilterContext(
  ({
    createTextMessage,
    onComplete,
    clearState,
    audienceOptions,
    getNotificationEstimate,
    setShowOptions,
    setIsTextTitle,
  }) => {
    const [textTarget, setTextTarget] = useState();
    const [audienceTarget, setAudienceTarget] = useState();
    const [trackTarget, setTrackTarget] = useState();
    const [seriesTarget, setSeriesTarget] = useState();
    const [eventTarget, setEventTarget] = useState();
    const [memberTarget, setMemberTarget] = useState();

    const thisYear = Number(moment().format('YYYY'));
    const [year, setYear] = useState(thisYear);

    const [guestYear, setGuestYear] = useState();
    const [guestTarget, setGuestTarget] = useState();
    const [listTarget, setListTarget] = useState();
    const [estimate, setEstimate] = useState(null);
    const [showEstimate, setShowEstimate] = useState(true);

    const [showComposeMessage, setShowComposeMessage] = useState(false);

    const {
      state: { accountId },
    } = useContext(AppContext);

    const { data } = useGetNotificationEvents();

    const resetForm = () => {
      setTextTarget('');
      setAudienceTarget('');
      setTrackTarget('');
      setSeriesTarget('');
      setEventTarget('');
      setListTarget('');
      setEstimate(null);
      setMemberTarget('');
    };

    useEffect(() => {
      resetForm();
    }, [clearState]);

    useEffect(() => {
      const fetchResults = async () => {
        const results = await getNotificationEstimate({
          textTarget: textTarget ? textTarget : null,
          audienceTarget: audienceTarget ? audienceTarget : null,
          trackTarget: trackTarget ? trackTarget.value : null,
          seriesTarget: seriesTarget ? seriesTarget.value : null,
          eventTarget: eventTarget ? eventTarget.value : null,
          listTarget: listTarget ? listTarget.value : null,
          guestTarget: guestTarget ? guestTarget.value : null,
          guestYear: guestYear ? guestYear.value : null,
          annualTarget: memberTarget ? memberTarget.value : null,
          account_id: accountId ? accountId : null,
          year: year ? year : null,
        });

        if (results && results.data) {
          setEstimate(results.data.getNotificationEstimate);
        }
      };
      fetchResults();
    }, [
      textTarget,
      audienceTarget,
      trackTarget,
      seriesTarget,
      eventTarget,
      listTarget,
      guestTarget,
      guestYear,
      getNotificationEstimate,
      memberTarget,
      accountId,
      year,
    ]);

    useEffect(() => {
      if (
        audienceTarget &&
        audienceTarget === 'track-favorites' &&
        data.me.ownership.tracks.length === 1
      ) {
        setTrackTarget(data?.me?.ownership?.tracks[0]?.id);
      }

      if (
        audienceTarget &&
        audienceTarget === 'series-favorites' &&
        data.me.ownership.series.length === 1
      ) {
        setSeriesTarget(data?.me?.ownership?.series[0]?.id);
      }
    }, [audienceTarget, data]);

    const showMessageField = () => {
      return (
        (audienceTarget && audienceTarget === 'all-users') ||
        (textTarget && textTarget === 'guests' && (guestTarget || guestYear)) ||
        (textTarget &&
          ['promoters', 'staff', 'promoters-staff'].includes(textTarget)) ||
        (audienceTarget &&
          audienceTarget === 'track-favorites' &&
          trackTarget) ||
        (audienceTarget &&
          audienceTarget === 'series-favorites' &&
          seriesTarget) ||
        (textTarget && textTarget === 'lists' && listTarget) ||
        (audienceTarget &&
          audienceTarget === 'event-participants' &&
          eventTarget) ||
        (audienceTarget && audienceTarget === 'memberships' && memberTarget) ||
        (audienceTarget && audienceTarget === 'credits' && accountId && year)
      );
    };

    const getAudienceOptions = () => {
      if (data.me.ownership.tracks.length && data.me.ownership.series.length) {
        return audienceOptions.full;
      }

      if (data.me.ownership.tracks.length) {
        return audienceOptions.track;
      }

      if (data.me.ownership.series.length) {
        return audienceOptions.series;
      }

      return audienceOptions.other;
    };

    return !showComposeMessage ? (
      <>
        <Spacer size={25} />
        <Select
          label="Send Message To"
          options={textTargets}
          onChange={(val) => {
            setTextTarget(val.target.value);
            if (val.target.value === 'lists') setShowEstimate(false);
          }}
          value={textTarget}
        />
        {textTarget === 'users' && (
          <>
            <Spacer size={25} />
            <Radio
              label="Send Message To"
              options={getAudienceOptions()}
              onChange={(val) => {
                setAudienceTarget(val.target.value);
              }}
              value={audienceTarget}
            />
          </>
        )}
        {textTarget === 'guests' && (
          <GuestOptions
            event={guestTarget}
            setEvent={setGuestTarget}
            setYear={setGuestYear}
            events={
              data && data.getUserNotificationEvents
                ? data.getUserNotificationEvents.results
                : []
            }
          />
        )}
        {audienceTarget &&
          audienceTarget === 'track-favorites' &&
          data.me.ownership.tracks.length > 1 && (
            <>
              <Spacer size={25} />
              <AutoSuggest
                name="track_id"
                label="Track"
                value={trackTarget}
                onChange={(value) => {
                  setTrackTarget(value);
                }}
                closeMenuOnSelect
                options={data.me.ownership.tracks.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </>
          )}

        {audienceTarget &&
          audienceTarget === 'series-favorites' &&
          data.me.ownership.series.length > 1 && (
            <>
              <Spacer size={25} />
              <AutoSuggest
                name="series_id"
                label="Series"
                value={seriesTarget}
                onChange={(value) => {
                  setSeriesTarget(value);
                }}
                closeMenuOnSelect
                options={data.me.ownership.series.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </>
          )}

        {audienceTarget &&
          textTarget === 'users' &&
          audienceTarget === 'event-participants' && (
            <>
              <Spacer size={25} />
              <YearDropdown />
            </>
          )}

        {audienceTarget &&
          textTarget === 'users' &&
          audienceTarget === 'event-participants' && (
            <>
              <Spacer size={25} />
              <AutoSuggest
                name="event_id"
                label="Event"
                isClearable
                placeholder="Event"
                onChange={(value) => {
                  setEventTarget(value);
                }}
                value={eventTarget}
                closeMenuOnSelect
                options={data.getUserNotificationEvents.results.map((item) => ({
                  value: item.id,
                  label: item.isMultiDay
                    ? `${item.month} ${item.listDates} - ${moment(
                        item.fullDate
                      ).format('YYYY')}`.toUpperCase() + ` — ${item.name}`
                    : `${moment(item.fullDate).format(
                        'ddd MMM D - YYYY'
                      )}`.toUpperCase() + `— ${item.name}`,
                }))}
              />
            </>
          )}

        {textTarget && textTarget === 'lists' ? (
          <>
            <Spacer size={25} />
            <AutoSuggest
              name="list_id"
              label="Text List Name / Description"
              isClearable
              placeholder="Text List"
              onChange={(value) => {
                setShowEstimate(false);
                setListTarget(value);
              }}
              value={listTarget}
              closeMenuOnSelect
              options={data.getPromoterLists.map((item) => ({
                value: item.id,
                label: item.name,
                code: item.code,
                recipientCount: item.recipient_count,
                replyMessage: item.reply_message,
              }))}
            />
          </>
        ) : null}

        {audienceTarget && audienceTarget === 'memberships' && (
          <MemberDropdown
            setMemberTarget={setMemberTarget}
            accountId={accountId}
          />
        )}

        {audienceTarget && audienceTarget === 'credits' && (
          <OpenCreditDropdown year={year} setYear={setYear} />
        )}

        {listTarget ? (
          <>
            <TextListData listTarget={listTarget} />
          </>
        ) : null}

        <Spacer size={70} />
        {showEstimate ? <Estimate estimate={estimate} type={'text'} /> : null}
        <ComposeButton
          onClick={() => {
            setShowOptions(false);
            setIsTextTitle(true);
            setShowComposeMessage(true);
          }}
          buttonStyle={{ height: 35 }}
          disabled={!showMessageField() || estimate === 0}
        >
          {'Compose Message'}
        </ComposeButton>
        <Spacer size={30} />
      </>
    ) : (
      <>
        <TextForm
          onSubmit={async ({ message }) => {
            const response = await createTextMessage({
              message,
              textTarget,
              audienceTarget,
              trackTarget: trackTarget?.value,
              seriesTarget: seriesTarget?.value,
              eventTarget: eventTarget?.value,
              listTarget: listTarget?.value,
              guestTarget: guestTarget?.value,
              guestYear: guestYear?.value,
              annualTarget: memberTarget?.value,
              account_id: accountId ? accountId : null,
              year: year ? year : null,
            });

            if (response && !response.errors) {
              resetForm();
              onComplete();
            }

            return response;
          }}
          estimate={estimate}
          type={'text'}
        />
      </>
    );
  }
);

export default compose(
  CreateTextMessage,
  GetNotificationEstimate
)(TextMessageForm);
