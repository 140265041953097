import { Formik } from 'formik';
import React, { Fragment, useContext, useState } from 'react';
import { getCountryCallingCode } from 'react-phone-number-input';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled, { useTheme } from 'styled-components';
import { AccountsSelect } from 'components/AccountsDropdown';
import { Button } from 'components/Button';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { DrawerPadding } from 'components/Drawer';
import { Input } from 'components/Form/Input';
import { PhoneInput } from 'components/Form/PhoneInput';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { useMe } from 'hooks/useMe';
import {
  ResponsiveCol,
  ResponsiveRow,
  TabContent,
} from 'pages/app/Events/AddEvents/styles';
import { ButtonWrapper } from 'pages/app/Users/UserEdit';
import Capabilities from 'pages/app/Users/UserEdit/Capabilities';
import { TitleContainer } from 'pages/track/Contact/style';
import { AppContext } from 'shared/AppContext';
import CreateEmployee from '../gql/mutations/create-employee';
import UpdateEmployee from '../gql/mutations/update-employee';
import { useGetEmployee } from '../gql/queries/get-employee';
import EmployeeSearch from './employee-search';

const Label = styled.p`
  margin-bottom: 20px;
  color: #fff;
  font-weight: 600;
  font-family: 'Roboto';
  text-align: left;
  font-size: 5.4vw;
  line-height: 29px;

  @media (min-width: 768px) {
    font-size: 20px;
    color: #000;
  }
`;

export const Tabs = styled.div`
  overflow: hidden;
  background: #fff;
  font-family: Barlow Condensed;
  font-weight: 600;
  height: 3.5em;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    overflow: visible;
    background: #fff;
    height: 7em;
    padding: 10px;
  }
`;

export const Tab = styled.button.attrs({ type: 'button' })`
  border: none;
  color: ${(props) => (props.active ? '#FE5000' : '#000000')};
  outline: none;
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : '12%')};
  position: relative;
  margin-right: 0.1em;
  font-size: 1em;
  font-weight: bold;
  border-bottom: ${(props) => (props.active ? '3px solid #FE5000' : '')};
  background-color: ${(props) => (props.active ? 'white' : 'white')};
  height: ${(props) => (props.active ? '3em' : '2.6em; top:.4em')};
  transition: background-color 0.5s ease-in-out;
  font-family: Barlow Condensed;
  :hover {
    background-color: white;
  }

  @media (max-width: 768px) {
    width: ${(props) => (props.width ? props.width : '12%')};
    font-size: 1.3em;
  }
`;

const formValues = [
  'first_name',
  'last_name',
  'cellphone',
  'country_code',
  'calling_code',
  'email',
  'employee',
  'account_ids',
  'capabilities',
];

const capabilityKeys = [
  'account_id',
  'events',
  'guests',
  'add_guests',
  'messages',
  'add_promos',
  'promos',
  'credits',
  'issue_credits',
  'transactions',
  'issue_refunds',
  'registrations',
  'customers',
  'memberships',
  'staff',
  'add_staff',
  'stripe',
  'scan_tickets',
  'check_tickets',
  'create_messages',
  'edit_registrations',
  'check_in_guests',
  'edit_memberships',
  'edit_billing',
];

export const EmployeeEdit = compose(
  CreateEmployee,
  UpdateEmployee
)(({ history, createEmployeeV2, updateEmployeeV2 }) => {
  const successMessage = () =>
    toast.success(
      currentEmployee ? 'Staff Member Updated' : 'Staff Member Created'
    );
  const errorMessage = () =>
    toast.error(
      currentEmployee
        ? 'Error Updating Staff Member'
        : 'Error Creating Staff Member'
    );

  const theme = useTheme();
  const params = useParams();
  const currentEmployee = params.id;
  const {
    state: { accountId, user },
  } = useContext(AppContext);

  const {
    data: { me },
  } = useMe();
  const isSelfEdit = me.id === Number(currentEmployee);

  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const { data } = useGetEmployee(parseInt(currentEmployee));
  const employee = currentEmployee ? data?.getEmployeeV2 : null;

  const [showForm, setShowForm] = useState(false);

  const [showEmailField, setShowEmailField] = useState(false);

  function validate(values) {
    const errors = {};

    if (!values.cellphone) {
      errors.cellphone = 'Required';
      errors.employee = 'Required';
    }

    if (!values.first_name) {
      errors.first_name = 'Required';
    }

    if (!values.last_name) {
      errors.last_name = 'Required';
    }

    if (!values.email) {
      errors.email = 'Required';
    }

    return errors;
  }

  const accounts = employee
    ? employee?.accounts.map((account) => ({
        label: account.business_dba,
        value: account.id,
      }))
    : user.accounts.map((account) => ({
        label: account.business_dba,
        value: account.id,
      }));
  const initialValues = formValues.reduce((formValues, value) => {
    const capabilities = [];
    if (value === 'capabilities' && !currentEmployee) {
      accounts.forEach((account) => {
        capabilities.push(
          capabilityKeys.reduce(
            (acc, key) => ({ ...acc, [key]: false, account_id: account.value }),
            {}
          )
        );
      });
    }
    const initialValue =
      value === 'account_ids'
        ? accounts
        : employee
        ? value === 'cellphone'
          ? employee[value] && !employee[value].includes('+')
            ? `+${employee['calling_code'] ? employee['calling_code'] : '1'}${
                employee[value]
              }`
            : employee[value]
          : employee[value]
          ? employee[value]
          : ''
        : value === 'capabilities'
        ? currentEmployee && employee
          ? employee[value]
          : capabilities
        : '';

    return {
      ...formValues,
      [value]: initialValue,
    };
  }, {});

  async function handleSubmit(values, { setSubmitting, setErrors, resetForm }) {
    setSubmitting(true);

    // if (getUserOwnership().isMultiVenueOwner) {
    //   if (!values.ownership || values.ownership === '') {
    //     setFieldError('ownership', 'Required');
    //     createErrorMessage();
    //     setSubmitting(false);
    //     return;
    //   }
    // }

    const data = Object.entries(values).reduce((formValues, [key, value]) => {
      return {
        ...formValues,
        ...(value ? { [key]: value } : null),
      };
    }, {});

    if (currentEmployee) {
      data['capabilities'].forEach(
        (capability) => delete capability['__typename']
      );
    }

    const {
      first_name,
      last_name,
      cellphone,
      country_code,
      calling_code,
      email,
      account_ids,
      user_id,
      capabilities,
    } = data;

    const response = employee
      ? await updateEmployeeV2(
          {
            first_name,
            last_name,
            cellphone: calling_code
              ? cellphone.replace(`+${calling_code}`, '')
              : cellphone.replace(`+1`, ''),
            country_code,
            calling_code,
            email,
            user_id: currentEmployee ? parseInt(currentEmployee) : user_id,
            account_ids: account_ids
              ? account_ids && account_ids.length
                ? account_ids.map((account) => account.value)
                : [account_ids.value]
              : null,
            capabilities,
          },
          accountId
        )
      : await createEmployeeV2(
          {
            first_name,
            last_name,
            cellphone: calling_code
              ? cellphone.replace(`+${calling_code}`, '')
              : cellphone.replace(`+1`, ''),
            country_code,
            calling_code,
            email,
            account_ids: account_ids
              ? account_ids && account_ids.length
                ? account_ids.map((account) => account.value)
                : [account_ids.value]
              : null,
            capabilities,
          },
          accountId
        );

    setSubmitting(false);
    if (!response || response.errors) {
      errorMessage();
      return setErrors(response.errors);
    }

    successMessage();
    setShowForm(false);
    resetForm();
    history.push(`/admin-${userType}/employees`);
  }

  //tab configuration for capabailities under each account
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [active, setActive] = useState(0);

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };
  const platform =
    process.env.REACT_APP_PLATFORM === 'tickethoss'
      ? 'Ticket Hoss'
      : process.env.REACT_APP_PLATFORM === 'pitpay'
      ? 'Pit Pay'
      : 'Kart Pass';

  return (
    <Container>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          const addCapability = (account_ids) => {
            let newCapability;
            if (!account_ids) {
              setFieldValue('capabilities', []);
            } else if (values.capabilities?.length > account_ids?.length) {
              newCapability = values.capabilities.reduce((acc, capability) => {
                if (account_ids.find((id) => id === capability.account_id))
                  return [...acc, capability];
                else return acc;
              }, []);

              if (active === account_ids?.length)
                setActive(account_ids?.length - 1);
              setFieldValue('capabilities', newCapability);
            } else {
              const account_id = account_ids[account_ids.length - 1];
              newCapability = capabilityKeys.reduce((acc, key) => {
                return key === 'account_id'
                  ? { ...acc, account_id }
                  : { ...acc, [key]: false };
              }, {});
              setFieldValue('capabilities', [
                ...values.capabilities,
                newCapability,
              ]);
            }
          };

          return (
            <form onSubmit={handleSubmit}>
              <ContainerHeader>
                <TitleContainer>
                  <Text
                    type="heading"
                    as="h1"
                    color={theme.colors.text.header}
                    inlineStyle={{ marginRight: 25, whiteSpace: 'nowrap' }}
                  >
                    {currentEmployee
                      ? 'Edit Staff Member Details'
                      : 'Create New Staff Member'}
                  </Text>
                </TitleContainer>
                <Spacer size={15} />
                {!isMobile && (
                  <ButtonWrapper>
                    <Button type="submit" disabled={isSubmitting} block>
                      {currentEmployee
                        ? 'Update Staff Member'
                        : 'Add Staff Member'}
                    </Button>
                  </ButtonWrapper>
                )}
              </ContainerHeader>

              <>
                <DrawerPadding border>
                  {currentEmployee ? null : (
                    <>
                      <Label style={{ color: '#00001f' }}>
                        Enter the mobile number of the staff member you wish to
                        add.
                      </Label>
                      <Label style={{ color: '#00001f' }}>
                        {`If they are already a ${platform} App user, their number will
                    autopopulate and their ${platform} profile will be updated
                    once you select their name and tap to add staff member.`}
                      </Label>
                      <Label style={{ color: '#00001f' }}>
                        {`If they are not a ${platform} App user, you will see a form to
                    add them. Fill out the additional fields as required and
                    tap to add staff member.`}
                      </Label>
                    </>
                  )}

                  <ResponsiveRow>
                    {currentEmployee ? null : (
                      <ResponsiveCol style={{ marginTop: 20 }}>
                        <EmployeeSearch
                          value={values.employee}
                          showForm={(cellphone) => {
                            setFieldValue('cellphone', cellphone);
                            setShowForm(true);
                          }}
                          hideForm={() => {
                            setShowForm(false);
                            setShowEmailField(false);
                          }}
                          setFieldValue={setFieldValue}
                          setShowEmailField={setShowEmailField}
                        />
                        {showEmailField && (
                          <Fragment>
                            <Spacer size={15} />
                            <Input
                              id="email"
                              label="Email"
                              placeholder="name@email.com"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email ?? ''}
                              error={
                                errors.email && touched.email && errors.email
                              }
                            />
                          </Fragment>
                        )}
                      </ResponsiveCol>
                    )}
                    <ResponsiveCol>
                      <AccountsSelect
                        isMulti
                        name="account_ids"
                        addCapability={addCapability}
                        label="Track or Series"
                      />
                    </ResponsiveCol>
                    {currentEmployee ? <ResponsiveCol></ResponsiveCol> : null}
                  </ResponsiveRow>
                </DrawerPadding>
              </>

              {showForm || currentEmployee ? (
                <>
                  <DrawerPadding border>
                    <Label style={{ color: '#00001f' }}>
                      {currentEmployee
                        ? ''
                        : 'Fill out the form to add a new staff member'}
                    </Label>

                    <Spacer size={18} />
                    <ResponsiveRow>
                      <ResponsiveCol>
                        <PhoneInput
                          id="cellphone"
                          placeholder="___ ___ ____"
                          size={25}
                          label="Phone Number"
                          onBlur={handleBlur}
                          onChange={() => {
                            setShowForm(false);
                          }}
                          onCountryChange={(e) => {
                            if (e) {
                              setFieldValue('country_code', e ?? '');
                              setFieldValue(
                                'calling_code',
                                getCountryCallingCode(e) ?? ''
                              );
                            }
                          }}
                          value={values.cellphone}
                          error={errors.cellphone}
                        />
                      </ResponsiveCol>
                      <ResponsiveCol>
                        <Input
                          id="first_name"
                          label="First Name"
                          placeholder="First Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.first_name}
                          error={
                            errors.first_name &&
                            touched.first_name &&
                            errors.first_name
                          }
                        />
                      </ResponsiveCol>
                    </ResponsiveRow>
                    <Spacer size={18} />
                    <ResponsiveRow>
                      <ResponsiveCol>
                        <Input
                          id="last_name"
                          label="Last Name"
                          placeholder="Last Name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                          error={
                            errors.last_name &&
                            touched.last_name &&
                            errors.last_name
                          }
                        />
                      </ResponsiveCol>
                      <ResponsiveCol>
                        <Input
                          id="email"
                          label="Email"
                          placeholder="name@email.com"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          error={errors.email && touched.email && errors.email}
                        />
                      </ResponsiveCol>
                    </ResponsiveRow>
                  </DrawerPadding>
                </>
              ) : null}

              <DrawerPadding>
                {values.capabilities && values.account_ids && (
                  <>
                    <Text
                      type="heading"
                      as="h1"
                      color={theme.colors.text.header}
                      inlineStyle={{
                        margin: '25 5',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      User Permissions
                    </Text>
                    <Spacer size={10} />
                    <TabContent>
                      <div
                        style={{
                          backgroundColor: '#F4F4F4',
                          margin: '10 0',
                        }}
                      >
                        <Tabs>
                          {values?.capabilities.map((cap, index) => {
                            let tab;
                            if (values.account_ids) {
                              if (values.account_ids.length)
                                values.account_ids.forEach((account) => {
                                  if (account.value === cap.account_id)
                                    tab = account;
                                });
                              else {
                                if (values.account_ids.value === cap.account_id)
                                  tab = values.account_ids;
                              }
                            }
                            return (
                              <Tab
                                onClick={(e) => handleClick(e)}
                                active={active === index}
                                width={isMobile ? '100%' : null}
                                id={index}
                                key={index}
                              >
                                {tab?.label}
                              </Tab>
                            );
                          })}
                        </Tabs>
                      </div>
                    </TabContent>

                    <Spacer size={18} />
                    {values.capabilities.map(
                      (capability, index) =>
                        active === index && (
                          <Capabilities
                            key={index}
                            mainValue={values}
                            values={capability}
                            errors={errors}
                            touched={touched}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            index={index}
                            handleChange={({ target }) => {
                              const newCapability = values.capabilities.map(
                                (capability, capIndex) =>
                                  capIndex === index
                                    ? {
                                        ...capability,
                                        [target.name]: target.value,
                                      }
                                    : capability
                              );
                              setFieldValue('capabilities', newCapability);
                            }}
                            handleMultipleChange={(target) => {
                              const newCapability = values.capabilities.map(
                                (capability, capIndex) =>
                                  capIndex === index
                                    ? {
                                        ...capability,
                                        ...target,
                                      }
                                    : capability
                              );
                              setFieldValue('capabilities', newCapability);
                            }}
                            capabilityKeys={capabilityKeys}
                            isSelfEdit={isSelfEdit}
                          />
                        )
                    )}
                  </>
                )}
              </DrawerPadding>
              {isMobile && (
                <DrawerPadding>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: '80%' }}
                    block
                  >
                    {currentEmployee
                      ? 'Update Staff Member'
                      : 'Add Staff Member'}
                  </Button>
                </DrawerPadding>
              )}
              <Spacer size={18} />
            </form>
          );
        }}
      </Formik>
    </Container>
  );
});
