import FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { PrintButton } from 'components/PrintButton';
import colors from 'components/ThemeProvider/colors';
import useTheme from 'hooks/useTheme';
import { logDevError } from 'shared/alerts';
import { timeout } from 'shared/timeout';
import { useGetEventReportOptions } from '../gql/useGetEventReportOptions';
import { useGetLapSponsorshipReport } from '../gql/useGetLapSponsorshipReport.mutation';
import { useGetSpectatorReport } from '../gql/useGetSpectatorReport.mutation';
import { PrintModal } from '../Modals/PrintModal';
import checkbox from './checkbox.png';
import { Logo } from './logo';

export const PrintParticipants = ({ text, eventId, color, button, date }) => {
  const getSpectatorReport = useGetSpectatorReport(date ?? null);
  const getLapSponsorshipReport = useGetLapSponsorshipReport();
  const [disabled, setDisabled] = useState(false);
  const [shouldDisplayPrintModal, setShouldDisplayPrintModal] = useState(false);
  const theme = useTheme();

  const { data } = useGetEventReportOptions();
  if (!data || !data.getEventPrintReportOptions) return null;
  const eventPrintReportOptions = data.getEventPrintReportOptions;

  let filename = '';

  const getData = () => {
    return getSpectatorReport(eventId)
      .then((response) => {
        let title = '';
        const spectatorReport = response.data.getSpectatorReport;
        if (response.data.getSpectatorReport.event.isMultiDay) {
          title =
            moment(
              response.data.getSpectatorReport.event.start_date,
              'MM-DD-YYYY'
            )
              .format('MMM DD - ')
              .toUpperCase() +
            moment(
              response.data.getSpectatorReport.event.end_date,
              'MM-DD-YYYY'
            )
              .format('DD - YYYY')
              .toUpperCase();
        } else {
          title = moment(
            response.data.getSpectatorReport.event.start_date,
            'MM-DD-YYYY'
          )
            .format('dddd MMM DD - YYYY')
            .toUpperCase();
        }
        filename =
          'Ticket Holders List - ' +
          title +
          ' - ' +
          response.data.getSpectatorReport.event.track.name +
          ' - ' +
          response.data.getSpectatorReport.event.name;

        return { spectatorReport, title, filename };
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const getLapSponsorshipData = (sponsorshipId) => {
    return getLapSponsorshipReport(sponsorshipId)
      .then((response) => {
        let title = '';
        const lapSponsorshipReport = response.data.getLapSponsorshipReport;
        if (response.data.getLapSponsorshipReport.event.isMultiDay) {
          title =
            moment(
              response.data.getLapSponsorshipReport.event.start_date,
              'MM-DD-YYYY'
            )
              .format('MMM DD - ')
              .toUpperCase() +
            moment(
              response.data.getLapSponsorshipReport.event.end_date,
              'MM-DD-YYYY'
            )
              .format('DD - YYYY')
              .toUpperCase();
        } else {
          title = moment(
            response.data.getLapSponsorshipReport.event.start_date,
            'MM-DD-YYYY'
          )
            .format('dddd MMM DD - YYYY')
            .toUpperCase();
        }
        filename =
          'Lap Sponsorship - ' +
          title +
          ' - ' +
          response.data.getLapSponsorshipReport.event.track.name +
          ' - ' +
          response.data.getLapSponsorshipReport.event.name;

        return { lapSponsorshipReport, title, filename };
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const handleClick = (printOptionData) => {
    if (printOptionData) {
      try {
        setShouldDisplayPrintModal(false);
        generatePdf(printOptionData);
      } catch (error) {
        logDevError(error);
        toast.error('Error generating report');
      }
    } else toast.error('Error generating report');
  };

  function generatePdf(printOptionData) {
    setDisabled(true);
    return new Promise(async (resolve) => {
      let pdf;
      if (printOptionData) {
        if (printOptionData.action === 'getLapSponsorshipReport')
          pdf = await exportLapSponsorshiopPDF(printOptionData.id);
        else pdf = await exportPDF();
      } else pdf = await exportPDF();

      setDisabled(false);
      if (filename) {
        pdf.setProperties({
          title: filename,
        });
      }
      if (
        navigator.userAgent.includes('iPhone') &&
        navigator.maxTouchPoints > 1
      ) {
        var blob = new Blob([pdf.output('blob')], {
          type: 'data:application/pdf,',
        });
        FileSaver.saveAs(blob, filename + '.pdf');
      } else if (navigator.maxTouchPoints > 1) {
        pdf.output('datauri', filename + '.pdf');
      } else {
        // Generate the PDF Blob
        const blob = pdf.output('blob');

        // Create a Blob URL for the PDF
        const blobUrl = URL.createObjectURL(blob);

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = pdf.fileName ?? filename + '.pdf'; // Set the desired filename

        // Trigger a click event on the download link
        downloadLink.click();
      }
      timeout(resolve, 6000);
    });
  }

  //This will generate the spectator PDF report
  const exportPDF = async () => {
    const { spectatorReport, title, filename } = await getData();
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    const text = doc.splitTextToSize(title, pageWidth - 650, {});
    doc.text(text, 40, 40);
    doc.text(spectatorReport.event.name, 40, 60);
    doc.text(spectatorReport.event.track.name, 40, 80);
    doc.text(
      `Ticket Total (${spectatorReport.total_tickets}) - Total Sales ${spectatorReport.total_sales}`,
      40,
      100
    );
    doc.text('Ticket Holders List', 620, 100);
    if (Logo) {
      doc.addImage(Logo, 'PNG', 760, 85, 50, 27, '', 'FAST');
    }

    const headers = [
      [
        "PURCHASER'S NAME",
        'TICKETS',
        'TICKET NUMBER',
        "TICKET HOLDER'S NAME",
        'TICKET PRICE',
      ],
    ];

    const tableData = spectatorReport.data
      .map((tick) => {
        const ticket = tick.tickets;
        const areAllTicketsRefunded = ticket.reduce((allRefunded, ticket) => {
          return !ticket.refunded ? false : allRefunded;
        }, true);
        const nonRefundedTickets = ticket.filter((ticket) => !ticket.refunded);
        return areAllTicketsRefunded
          ? null
          : [
              tick.purchaser,
              nonRefundedTickets.reduce(
                (acc, ticket) =>
                  acc.length
                    ? acc.concat(`\r\n${ticket.ticket.name.toUpperCase()}`)
                    : `${ticket.ticket.name.toUpperCase()}`,
                ''
              ),
              nonRefundedTickets.reduce(
                (acc, ticket) =>
                  acc.length
                    ? acc.concat(`\r\n${ticket.barcode}`)
                    : `${ticket.barcode}`,
                ''
              ),
              nonRefundedTickets.reduce(
                (acc, ticket) =>
                  acc.length
                    ? acc.concat(`\r\n${ticket.holder.toUpperCase()}`)
                    : `${ticket.holder.toUpperCase()}`,
                ''
              ),
              nonRefundedTickets.reduce(
                (acc, ticket) =>
                  acc.length
                    ? acc.concat(`\r\n${ticket.price}`)
                    : `${ticket.price}`,
                ''
              ),
            ];
      })
      // Because anyone with no non-refunded tickets will otherwise be a blank row
      .filter((participant) => !!participant);

    doc.autoTable({
      startY: 120,
      rowPageBreak: 'avoid',
      columns: [
        { dataKey: "Purchaser's Name", header: "PURCHASER'S NAME" },
        { dataKey: 'Tickets', header: 'TICKETS' },
        { dataKey: 'Ticket Number', header: 'TICKET NUMBER' },
        { dataKey: "Ticket Holder's Name", header: "TICKET HOLDER'S NAME" },
        { dataKey: 'Ticket Price', header: 'TICKET PRICE' },
      ],
      head: headers,
      body: tableData,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: '#fa4616',
      },
      columnStyles: {
        "Purchaser's Name": {
          cellWidth: 200,
          cellPadding: { top: 5, right: 5, bottom: 5, left: 20 },
          textColor: theme.colors.text.black,
        },
      },
      didParseCell: function (tableData) {
        const hasNoWaiver = tableData.row.raw[6] === false;

        if (hasNoWaiver) {
          tableData.cell.styles.fillColor = '#feece8';
          tableData.cell.styles.textColor = colors.text.gray;
          tableData.cell.styles.opacity = 0.1;
        }
      },
      didDrawCell: function (tableData) {
        if (
          tableData.row.section === 'body' &&
          tableData.column.dataKey === "Purchaser's Name"
        ) {
          doc.addImage(
            checkbox,
            'PNG',
            tableData.cell.x + 5,
            tableData.cell.y + 4,
            12,
            12,
            '',
            'FAST'
          );
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');
      const dateTimeStamp = moment()
        .format('MMM DD - YYYY h:mm A')
        .toUpperCase();
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: filename,
      subject: 'Ticket Holders List',
      author: 'Pit Pay',
    });

    return { ...doc, fileName: filename };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //This will generate the Lap Sponsorship PDF report
  const exportLapSponsorshiopPDF = async (sponsorshipId) => {
    const { lapSponsorshipReport, title, filename } =
      await getLapSponsorshipData(sponsorshipId);
    let xIndex = 40;
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    const text = doc.splitTextToSize(title, pageWidth - 650, {});
    doc.text(text, 40, xIndex);
    xIndex += 20;
    doc.text(lapSponsorshipReport.name, 40, xIndex);
    xIndex += 20;
    doc.text(`# of Laps ${lapSponsorshipReport.num_laps}`, 40, xIndex);
    xIndex += 20;
    doc.text(`# of Laps Sold ${lapSponsorshipReport.laps_sold}`, 40, xIndex);
    xIndex += 20;
    doc.text(`Total ${lapSponsorshipReport.total_purse}`, 40, xIndex);
    lapSponsorshipReport.payouts.map((payout) => {
      xIndex += 20;
      return doc.text(`${payout.title} payout ${payout.amount}`, 40, xIndex);
    });
    doc.text('Lap Sponsorship', 620, xIndex);
    if (Logo) {
      doc.addImage(Logo, 'PNG', 760, xIndex - 15, 50, 27, '', 'FAST');
    }

    const headers = [['LAP', 'SPONSORED BY', 'LAP PRICE', "PURCHASER'S NAME"]];

    const tableData = lapSponsorshipReport.data.map((lap) => {
      return [
        lap.name ?? '',
        lap.lap_text ?? '',
        lap.price ?? '',
        lap.purchaser ?? '',
      ];
    });

    xIndex += 20;
    doc.autoTable({
      startY: xIndex,
      rowPageBreak: 'avoid',
      columns: [
        { dataKey: 'Lap', header: 'LAP' },
        { dataKey: 'Lap Text', header: 'SPONSORED BY' },
        { dataKey: 'Lap Price', header: 'LAP PRICE' },
        { dataKey: "Purchaser's Name", header: "PURCHASER'S NAME" },
      ],
      head: headers,
      body: tableData,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: '#fa4616',
      },
      columnStyles: {
        "Purchaser's Name": {
          cellWidth: 200,
          cellPadding: { top: 5, right: 5, bottom: 5, left: 20 },
          textColor: theme.colors.text.black,
        },
      },
      didParseCell: function (tableData) {
        const hasNoWaiver = tableData.row.raw[6] === false;

        if (hasNoWaiver) {
          tableData.cell.styles.fillColor = '#feece8';
          tableData.cell.styles.textColor = colors.text.gray;
          tableData.cell.styles.opacity = 0.1;
        }
      },
      didDrawCell: function (tableData) {
        if (
          tableData.row.section === 'body' &&
          tableData.column.dataKey === "Purchaser's Name"
        ) {
          doc.addImage(
            checkbox,
            'PNG',
            tableData.cell.x + 5,
            tableData.cell.y + 4,
            12,
            12,
            '',
            'FAST'
          );
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');
      const dateTimeStamp = moment()
        .format('MMM DD - YYYY h:mm A')
        .toUpperCase();
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: filename,
      subject: 'Lap Sponsorhip',
      author: 'TicketHoss',
    });

    return { ...doc, fileName: filename };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      <PrintButton
        setShouldDisplayPrintModal={setShouldDisplayPrintModal}
        eventPrintReportOptions={eventPrintReportOptions}
        buildPdf={generatePdf}
        text={text}
        variant="minimal"
        disabled={disabled}
        color={color}
        button={button}
      />
      <PrintModal
        title="Print PDF Report Options"
        description="Please select the report you would like to generate"
        printReportOptions={eventPrintReportOptions}
        isVisible={!!shouldDisplayPrintModal}
        handleClick={handleClick}
        setIsVisible={setShouldDisplayPrintModal}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    </div>
  );
};
