import React, { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { EventHeaderMobile } from 'components/Events';
import { useDuplicateEvent } from 'components/Events/gql';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { CancelModal } from '../CancelModal';
import { EventHeader } from './components/EventHeader';
import { MenuCard } from './components/MenuCard';
import { RefundEventFlow } from './components/RefundEventFlow';
import { useGetEvent } from './gql/queries/get-event';
import { PassesModal } from 'components/Participants/Modals';
import { AppContext } from 'shared/AppContext';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    margin: 0 10px;
    padding: 20px;
  }
`;

export const EventsMenu = () => {
  const { id } = useParams();
  const history = useHistory();
  const duplicateEvent = useDuplicateEvent();

  const [eventRefundIsVisible, setEventRefundIsVisible] = useState(false);

  const [currentEvent, setCurrentEvent] = useState(null);

  const openEventRefund = () => {
    setEventRefundIsVisible(true);
  };

  const closeEventRefund = () => {
    setEventRefundIsVisible(false);
  };

  const { data: eventData } = useGetEvent(parseInt(id));

  useEffect(() => {
    if (eventData && eventData?.getEvent) {
      setCurrentEvent(eventData.getEvent);
    }
  }, [eventData]);

  const pathname = useLocation().pathname;
  const userType =
    ['admin-employee', 'admin-track'].find((type) => pathname.includes(type)) ||
    'admin';

  const {
    state: { capabilities },
  } = useContext(AppContext);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPasses, setShowPasses] = useState(false);
  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const openPermissionModal = () => {
    setShowPermissionModal(true);
  };

  console.log('capabilities', capabilities);

  const MenuList = [
    {
      title: 'Scan Tickets',
      detail: 'Entering scan mode allows you to scan in tickets at the gate.',
      url: `/${userType}/scan/${id}?date=${currentEvent?.start_date}`,
      onClick:
        userType === 'admin' || capabilities?.scan_tickets
          ? null
          : openPermissionModal,
    },
    {
      title: 'View Spectators List',
      detail: 'View, search, and manually check-in spectators for your event.',
      url:
        userType === 'admin'
          ? `/${userType}/series/${currentEvent?.track_id}/events/${id}/participants?date=${currentEvent?.start_date}`
          : `/${userType}/track/${currentEvent?.track_id}/events/${id}/participants?date=${currentEvent?.start_date}`,
      onClick:
        userType === 'admin' || capabilities?.events
          ? null
          : openPermissionModal,
    },
    userType === 'admin' && {
      title: 'Edit Event',
      detail: 'Edit the event details, ticket types, and more.',
      url: `/admin/events/editEvent/${id}`,
    },
    userType === 'admin' && {
      title: 'Duplicate Event',
      detail: 'Create a new event with the same details as this event.',
      onClick: async () => {
        if (window.confirm('Are you sure you want to duplicate this event?')) {
          const response = await duplicateEvent(+id, currentEvent?.track_id);
          if (!response || response.errors) {
            toast.error('Could not duplicate this event');
          } else {
            toast.success('Event successfully duplicated');
          }
        }
      },
    },
    {
      title: 'Reports',
      detail: 'View and Print various reports for this event.',
      onClick:
        userType === 'admin' ||
        (capabilities?.events && userType === 'admin') ||
        capabilities?.transactions
          ? () =>
              history.push(`/${userType}/events/reports/${id}`, {
                event: currentEvent,
              })
          : openPermissionModal,
    },
    {
      title: 'Point of Sale',
      detail:
        'Entering the POS is a box office mode that allows you to sell tickets in person.',
      url: `/${userType}/cart/${id}`,
      onClick:
        userType === 'admin' || capabilities?.events
          ? null
          : openPermissionModal,
    },
    {
      title: 'View Transactions',
      detail:
        "Find a customer's purchase and troubleshoot any issues with their purchase.",
      url: `/${userType}/transactions/event/${id}`,
      onClick:
        userType === 'admin' || capabilities?.transactions
          ? null
          : openPermissionModal,
    },
    {
      title: 'Cancel / Postpone Event',
      detail:
        'This will turn off ticket sales and notify customers of the change.',
      onClick:
        userType === 'admin' || capabilities?.events
          ? () => setShowCancelModal(true)
          : openPermissionModal,
    },
    {
      title: 'Refund / Credit Event',
      detail:
        'Allows you the ability to issue credits & refunds to customers in bulk.',
      onClick:
        currentEvent?.issue_credits && currentEvent?.issue_refunds
          ? isMobile
            ? null
            : openEventRefund
          : openPermissionModal,
      url: `/${userType}/events/refund/${id}`,
    },
    currentEvent?.has_reserved_seats && {
      title: 'Reserved Seats',
      detail: 'View and Manager your Reserved Seat options',
      onClick:
        userType === 'admin' || capabilities?.events
          ? () =>
              history.push(`/${userType}/events/reservedSeats/${id}`, {
                event: currentEvent,
              })
          : openPermissionModal,
    },
    {
      title:
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Passes on Sale'
          : 'Tickets on Sale',
      detail: `View the ${
        process.env.REACT_APP_PLATFORM !== 'tickethoss' ? 'Passes' : 'Tickets'
      } that are currently on sale.`,
      onClick:
        userType === 'admin' || capabilities?.events
          ? () => setShowPasses(true)
          : openPermissionModal,
    },
    process.env.REACT_APP_PLATFORM === 'pitpay' && {
      title: 'Registrations',
      detail: 'View and manage registrations for this event.',
      url: `/${userType}/registrations/event/${id}`,
      onClick:
        userType === 'admin' || capabilities?.registrations
          ? null
          : openPermissionModal,
    },
    userType !== 'admin' && {
      title: 'Guests',
      detail: 'View and manage guests for this event.',
      url: `/${userType}/guest-lists/event/${id}`,
      onClick:
        userType === 'admin' || capabilities?.guests
          ? null
          : openPermissionModal,
    },
  ];

  const MenuContainer = (
    <Container>
      {MenuList.filter(Boolean).map((menu, index) => (
        <MenuCard key={index} {...menu} />
      ))}
      {showCancelModal && (
        <CancelModal
          close={() => setShowCancelModal(false)}
          selectedEvent={currentEvent}
          objectId={id}
        />
      )}
    </Container>
  );

  return (
    <>
      {isMobile ? (
        <>
          <EventHeaderMobile queryDate={currentEvent?.date} noDropdown />
          {MenuContainer}
        </>
      ) : (
        <SearchableListContainer
          header={currentEvent && <EventHeader event={currentEvent} />}
          noSearch
          noContainerHeader
        >
          {MenuContainer}
        </SearchableListContainer>
      )}

      <RefundEventFlow
        isVisible={eventRefundIsVisible}
        close={closeEventRefund}
        event={currentEvent}
      />

      <PassesModal
        showModal={showPasses}
        event={currentEvent}
        close={() => {
          setShowPasses(false);
        }}
      />

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/${userType}/settings/1`}
        history={history}
      />
    </>
  );
};
