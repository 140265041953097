import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { DateCard } from 'components/DateCard';
import Icon from 'components/Icon';

const HeaderContainer = styled.div`
  /* background-color: #191949; */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
  justify-content: start;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const EventName = styled.span`
  font-family: Barlow Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
`;

const EventTrack = styled.span`
  //styleName: PP - Large Body;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #adadad;
  display: flex;
  align-items: center;
`;

export const TicketHeader = ({ event }) => {
  let item = null;
  if (event?.start_date) {
    item = {
      date: moment(event?.start_date, 'MM-DD-YYYY').format('MMM D'),
      day: moment(event?.start_date, 'MM-DD-YYYY').format('ddd'),
      month: moment(event?.start_date, 'MM-DD-YYYY').format('MMM'),
      end_date: moment(event?.end_date, 'MM-DD-YYYY').format('MM-DD-YYYY'),
      isMultiDay: event?.isMultiDay ?? true,
      listDates:
        moment(event?.start_date, 'MM-DD-YYYY').format('DD') +
        ' - ' +
        (event?.end_date
          ? moment(event?.end_date, 'MM-DD-YYYY').format('DD')
          : ''),
    };
  }

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <HeaderContainer>
      <div style={{ minWidth: 100 }}>
        {item !== null && (
          <DateCard
            item={item}
            margin={'margin: 0 10px 0 0'}
            horizontal={!isMobile ? true : false}
            isCartList={!isMobile ? true : false}
          />
        )}
      </div>

      <div
        style={{
          margin: 10,
          display: 'flex',
          justifyContent: 'start',
          flexDirection: 'column',
        }}
      >
        <EventName>{event?.name}</EventName>
        <EventTrack>
          {' '}
          <Icon icon="location" size={20} color={'#adadad'} />{' '}
          {event?.track.name ?? event?.series.name}
        </EventTrack>
      </div>
    </HeaderContainer>
  );
};
