import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import { useCart } from 'shared/CartContext';

const TicketContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 24px;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const TicketName = styled.span`
  //styleName: PP - H3;
  font-family: Barlow Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  flex: 1;
`;

const PriceContainer = styled.div``;

const PriceButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

const Price = styled.span`
  //styleName: PP - H2;
  font-family: Barlow Condensed;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const CounterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const DeleteButton = styled.div`
  display: flex;
  border: 2px solid #fa4616;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: 56px;
  height: 50px;
  padding: 7px, 16px, 9px, 16px;
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
`;

const CountTextContainer = styled.div`
  width: 48px;
  height: 50px;
  padding: 0px, 18px, 0px, 18px;
  border-radius: 8px;
  gap: 10px;
  display: flex;
  justify-content: center;
`;

const CountText = styled.span`
  font-family: Barlow Condensed;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;
  align-items: center;
  display: flex;
`;

export const Ticket = ({ ticket }) => {
  const { addTicket, removeTicket, setSeatsModalIsVisible } = useCart();

  const onAddTicket = () => {
    localStorage.setItem('selectedTicket', ticket.id);
    if (ticket?.reserved_seat) {
      setSeatsModalIsVisible(true);
    } else addTicket(ticket);
  };

  const onRemoveTicket = () => {
    localStorage.setItem('selectedTicket', ticket.id);
    if (ticket?.reserved_seat) {
      setSeatsModalIsVisible(true);
    } else removeTicket(ticket);
  };

  return (
    <TicketContainer>
      <TicketName>{ticket?.name}</TicketName>
      <PriceButtonContainer>
        <PriceContainer>
          <Price>{`$${ticket?.price.toFixed(2)}`}</Price>
        </PriceContainer>
        {ticket?.count === 0 ? (
          <Button
            buttonStyle={{
              height: 50,
              width: 168,
            }}
            fontSize={24}
            fontWeight={700}
            onClick={onAddTicket}
          >
            Add to Cart
          </Button>
        ) : (
          <CounterContainer>
            <DeleteButton onClick={onRemoveTicket}>
              <Icon icon="trash" size={20} color={'#fa4616'} />
            </DeleteButton>
            <CountTextContainer>
              <CountText>{ticket?.count}</CountText>
            </CountTextContainer>
            <Button
              buttonStyle={{ width: 56, height: 50 }}
              onClick={onAddTicket}
            >
              {' '}
              <Icon icon="plus" size={20} color={'#fff'} />
            </Button>
          </CounterContainer>
        )}
      </PriceButtonContainer>
    </TicketContainer>
  );
};
