import React, { useEffect } from 'react';
import styled from 'styled-components';
import Spacer from 'components/Spacer';
import { useCart } from 'shared/CartContext';
import { useGetGATickets } from './gql/queries/useGetGATickets';
// import Badge from './icons/badge';
// import Fastfood from './icons/fastfood';
// import Merch from './icons/merch';
// import TicketIcon from './icons/ticketIcon';
import SeatsManagerModal from './SeatsManager';
import { Ticket } from './Ticket';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TicketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 24px;
  ${'' /* margin-left: 24px; */}
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  padding-right: 200px;
  font-weight: bold;
`;

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: '20px 0';
  justify-content: start;
  gap: 5px;
  overflow-x: auto;

  @media (max-width: 768px) {
    max-width: -webkit-fill-available;
  }
`;

const TicketContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 66px;
  max-width: 197px;
  flex: 1;
  cursor: pointer;
  background-color: ${(props) => (!props.selected ? 'transparent' : '#fa4616')};
  border: ${(props) =>
    props.selected ? null : '2px solid rgba(250, 70, 22, 0.4)'};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  color: ${(props) => (props.selected ? 'white' : 'rgba(250, 70, 22, 0.4)')};
`;

const ButtonText = styled.span`
  //styleName: PP - H3;
  font-family: Barlow Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
`;

const TicketsContainer = styled.div`
  max-height: 55vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 984px) {
    max-height: 35vh;
  }
`;

export const TicketList = ({ event }) => {
  const {
    tickets,
    setTickets,
    setTypes,
    seatsModalIsVisible,
    setSeatsModalIsVisible,
    setTicketCount,
    selected,
    setSelected,
  } = useCart();

  const { data, loading } = useGetGATickets();

  useEffect(() => {
    if (!loading && data?.getGATicketsV2.length > 0) {
      // Compare the saved tickets and the tickets from the API based on id and price, if it's the same, set the count to the saved count
      const savedTickets = localStorage.getItem(`tickets`);
      const storedTickets = JSON.parse(savedTickets);

      let ticketCount = 0;

      const ticketWithCount = Object.keys(data.getGATicketsV2)?.reduce(
        (acc, key) => {
          setTypes((types) =>
            types?.length > 0
              ? types.concat(data.getGATicketsV2[key]?.type)
              : [data.getGATicketsV2[key]?.type]
          );

          return {
            ...acc,
            [key]: {
              ...data.getGATicketsV2[key],
              tickets: data.getGATicketsV2[key]?.tickets?.map((ticket) => {
                const savedTicket = storedTickets[key]?.tickets?.find(
                  (t) =>
                    t.id === ticket.id && t.track_price === ticket.track_price
                );

                if (savedTicket) ticketCount += savedTicket.count;

                return {
                  ...ticket,
                  price: ticket.track_price ? ticket.track_price : ticket.price,
                  count: savedTicket ? savedTicket.count : 0,
                  ...(ticket.reserved_seat
                    ? { seats: savedTicket?.seats }
                    : null),
                };
              }),
            },
          };
        },
        {}
      );

      setTicketCount(ticketCount);
      setTickets(ticketWithCount);
    }
  }, [
    data,
    loading,
    setTicketCount,
    setTickets,
    setTypes,
    tickets.reserved_seat,
  ]);

  return (
    <ListContainer>
      <TabContainer>
        {Object.keys(tickets)?.map((key) => (
          <TicketContainer
            key={key}
            selected={selected === key}
            onClick={() => setSelected(key)}
          >
            <img
              style={{
                width: '28px',
                height: '28px',
                color: selected === key ? 'white' : '#FA461633',
              }}
              src={tickets[key]?.type?.logo}
              alt={`${tickets[key]?.type?.name}`}
            />
            <ButtonText>{tickets[key]?.type?.name}</ButtonText>
          </TicketContainer>
        ))}
      </TabContainer>

      {/*<TicketContainer
          selected={selected === 1}
          onClick={() => setSelected(1)}
        >
          <Fastfood fillColor={selected === 1 ? 'white' : '#FA461633'} />
          <ButtonText>Food</ButtonText>
        </TicketContainer>

        <TicketContainer
          selected={selected === 2}
          onClick={() => setSelected(2)}
        >
          <Merch fillColor={selected === 2 ? 'white' : '#FA461633'} />
          <ButtonText>Merch</ButtonText>
        </TicketContainer>

        <TicketContainer
          selected={selected === 3}
          onClick={() => setSelected(3)}
        >
          <Badge fillColor={selected === 3 ? 'white' : '#FA461633'} />
          <ButtonText>Other</ButtonText>
        </TicketContainer>
      </TabContainer> */}
      <Spacer size={20} />
      <TicketHeader>
        <span style={{ marginLeft: '24px' }}>TICKET TYPE</span>
      </TicketHeader>
      <TicketsContainer>
        {tickets[selected]?.tickets?.map((ticket) => (
          <Ticket ticket={ticket} key={ticket.id} />
        ))}
      </TicketsContainer>

      <SeatsManagerModal
        isVisible={seatsModalIsVisible}
        setIsVisible={setSeatsModalIsVisible}
        event={event}
      />
    </ListContainer>
  );
};
