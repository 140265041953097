import moment from 'moment';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { EventHeaderMobile } from 'components/Events';
import { PrintParticipants } from 'components/Participants/PrintSpectators';
import { SearchableListContainer } from 'components/SearchableListContainer';
import Export from 'pages/app/Tickets/export';
import { TicketReportPrint } from 'pages/app/Transactions/TicketReportPrint/TicketReportPrint';
import { TransactionsPrintList } from 'pages/app/Transactions/TransactionsPrintList';
import { EventHeader } from '../EventsMenu/components/EventHeader';
import { MenuCard } from '../EventsMenu/components/MenuCard';

const Container = styled.div`
  // Grid display with a maximum of 4 columns
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-content: center;

  // Responsive design
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    margin: 0 10px 10px 10px;
    padding: 20px;
  }
`;

export const EventsReport = () => {
  // Get id from the URL
  const { id } = useParams();

  const location = useLocation();
  const { event } = location.state || {};

  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : pathname.split('/').includes('admin-track')
    ? 'track'
    : 'admin';

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const MenuList = [
    {
      title: 'Specators List PDF',
      detail:
        'PDF report containing a list of all spectators. Report contains purchaser, ticket #, etc.',
      component: (
        <PrintParticipants
          eventId={id}
          text="Print List"
          date={event?.start_date}
        />
      ),
    },
    {
      title: 'Specators List CSV',
      detail:
        'CSV report containing a list of all spectators. Report contains purchaser, ticket #, etc.',
      component: <Export event={event} date={event?.start_date} />,
    },
    {
      title: 'Transactions Report',
      detail:
        'This is the Payout report which containing all transactions for this event.',
      component: (
        <TransactionsPrintList
          date={
            event?.isMultiDay
              ? `${event?.fullMonth} ${event?.listDates} - ${moment(
                  event?.end_date
                ).format('YYYY')} `
              : event?.fullDate
          }
          eventId={event?.id}
        />
      ),
    },
    {
      title: 'Ticket Breakdown Report',
      detail: 'Report breaks down how many of each ticket were sold.',
      component: (
        <TicketReportPrint
          date={
            event?.isMultiDay
              ? `${event?.fullMonth} ${event?.listDates} - ${moment(
                  event?.end_date
                ).format('YYYY')} `
              : event?.fullDate
          }
          eventId={event?.id}
        />
      ),
    },
    {
      title: 'Register Cash Out Report',
      detail:
        'Report used to balance the box office register at the end of the day.',
      url: `/${userType}/transactions/event/${id}`,
    },
    {
      title: 'Event Transaction Audit',
      detail:
        'Breaks down which ticket sold and method of payment used. Cash, Card, Credit, etc.',
      url: '/settings',
    },
  ];

  const MenuContainer = (
    <Container>
      {MenuList.map((menu, index) => (
        <MenuCard key={index} {...menu} />
      ))}
    </Container>
  );

  return (
    <>
      {isMobile ? (
        <>
          <EventHeaderMobile queryDate={event?.date} noDropdown />
          {MenuContainer}
        </>
      ) : (
        <SearchableListContainer
          header={event ? <EventHeader event={event} /> : undefined}
          title="Reports"
          noSearch
        >
          {MenuContainer}
        </SearchableListContainer>
      )}
    </>
  );
};
