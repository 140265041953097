import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';
import { usePOSRequest } from './gql/queries/usePOSRequest';

const CheckoutFormStripe = ({ amount, paymentIntent }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [paymentInProgress, setPaymentInProgress] = useState(false);

  const handleSubmit = async (event) => {
    setPaymentInProgress(true);
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      setPaymentInProgress(false);
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret: paymentIntent,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    setPaymentInProgress(false);
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      errorMessage();
      setErrorMessage(error.message);
      setPaymentInProgress(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '80vh',
          paddingInline: 34,
        }}
      >
        <PaymentElement />
        <div>
          {/* Show error message to your customers */}
          {errorMessage && <div>{errorMessage}</div>}
          {paymentInProgress ? (
            <Loading />
          ) : (
            <Button
              buttonStyle={{
                borderRadius: 8,
                minWidth: '70%',
                marginInline: 'auto',
                flex: 1,
                padding: '13px 0',
              }}
              type="submit"
            >
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '34px',
                }}
              >
                Charge ${(amount / 100).toFixed(2)}
              </span>
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PAYMENT_KEY);

export const CheckoutForm = ({
  isVisible,
  setIsVisible,
  amount,
  accountId,
}) => {
  const theme = useTheme();

  const posRequest = usePOSRequest();

  const [paymentIntent, setPaymentIntent] = useState(null);

  useEffect(() => {
    const fetchPaymentIntent = async () => {
      const result = await posRequest({
        account_id: accountId,
        amount: amount,
      });

      if (result) {
        setPaymentIntent(result.data.posRequest);
      }
    };

    if (isVisible) fetchPaymentIntent();
  }, [accountId, amount, posRequest, isVisible]);

  const appearance = {
    theme: 'stripe',

    variables: {
      colorPrimary: theme.colors.primary,
      colorBackground: '#ffffff',
      colorText: 'black',
      colorDanger: theme.colors.error,
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      textAlign: 'left',
      spacingUnit: '2px',
      borderRadius: '4px',
    },
    primaryButton: {
      shapes: {
        borderRadius: 20,
      },
    },
  };

  const options = {
    mode: 'payment',
    amount: amount,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: appearance,
  };

  const isTab = useMediaQuery({ query: '(max-width: 1190px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <Modal
      maxWidth={600}
      modalStyle={{
        height: '95vh',
        maxHeight: '95vh',
        padding: 0,
        right: isMobile ? 0 : 20,
        top: isTab ? '30px' : '2.5vh',
        maxWidth: 550,
        margin: 'auto 0',
        width: isMobile ? '100vw' : '40vw', // Conditional width
        minWidth: isMobile ? 450 : null, // Full width on small screens
      }}
      isVisible={isVisible}
      hideModal={() => setIsVisible(false)}
      noHeader={true}
      overlayStyle={{
        zIndex: 11,
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            padding: 10,
            justifyContent: 'space-between',
            marginBottom: 10,
            borderBottom: '1px solid #EAEAEA',
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 26,
              fontWeight: 'bold',
              paddingBottom: 20,
              marginLeft: 25,
              fontFamily: 'Barlow Condensed',
            }}
          >
            Key in Card Number
          </div>
          <div
            style={{
              transform: 'rotate(45deg)',
              display: 'flex',
              justifyContent: 'end',
              marginRight: 20,
            }}
          >
            <Icon
              icon="plus"
              color={theme.colors.primary}
              size={24}
              onClick={() => setIsVisible(false)}
              style={{ transform: 'rotate(45deg)', fontWeight: 'bold' }}
            />
          </div>
        </div>

        <div id="checkout" style={{ padding: 20 }}>
          {paymentIntent && (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutFormStripe
                amount={amount}
                paymentIntent={paymentIntent}
              />
            </Elements>
          )}
        </div>
      </div>
    </Modal>
  );
};
