import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import { useGetParticipantsDownload } from 'components/Participants/gql/useGetParticipantsDownload';
import { PrintParticipants } from 'components/Participants/PrintParticipants';
import Text from 'components/Text';
import useNewPurchase from 'hooks/useNewPurchase';
import useTheme from 'hooks/useTheme';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import Export from './export';

const ButtonAction = styled.button`
  padding: 0;
  margin: 0 10px 0 10px;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

const HeaderButtons = ({ event, date, match, setShowCancelModal }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const theme = useTheme();
  const { data, subscribeToMore } = useGetParticipantsDownload();
  useNewPurchase({ subscribeToMore }, event.id, '', date);

  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const {
    state: { capabilities },
  } = useContext(AppContext);

  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  if (!data || !data.getParticipantsList) return null;

  const participants = data.getParticipantsList;

  const handleClickScan = (id, date) => {
    pathname.includes('/admin-track/') || pathname.includes('/admin-employee/')
      ? history.push(`/admin-${userType}/scan/${id}?date=${date}`)
      : history.push(`/admin/scan/${id}?date=${date}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Icon
          icon="ticket"
          size={32}
          color={theme.colors.primary}
          onClick={async () => {
            history.push(`/admin/cart/${event.id}`);
          }}
          padding="0 0 0 0"
        />
        <Button
          buttonStyle={{ width: 165, marginRight: 10 }}
          onClick={
            !capabilities ||
            capabilities.length === 0 ||
            capabilities?.scan_tickets
              ? () => handleClickScan(event.id, date)
              : () => setShowPermissionModal(true)
          }
        >
          {process.env.REACT_APP_PLATFORM !== 'tickethoss'
            ? 'SCAN PARTICIPANTS'
            : 'SCAN TICKETS'}
        </Button>
      </div>

      <Export
        participants={participants}
        event={event}
        icon={<Icon icon="Export-Icon" size={40} />}
      />
      <PrintParticipants
        refunded={participants
          .map((item) => (item.status === 'refunded' ? item.name : null))
          .filter(Boolean)}
        checkedIn={participants
          .map((item) => {
            const tickets = item.tickets.filter(
              (ticket) => ticket.is_checked && 'active' === ticket.status
            );
            return tickets.length === item.tickets.length ? item.name : null;
          })
          .filter(Boolean)}
        participants={participants}
        date={date}
        eventID={match.params.id}
        event={event}
        text="Print List"
      />
      {'published' === event.status ? (
        <ButtonAction
          onClick={() => {
            setShowCancelModal(true);
          }}
        >
          <Icon icon="cancel-event" size={40} color={theme.colors.primary} />
          <Text
            type="label"
            fontSize={12}
            color={theme.colors.secondary}
            style={{
              marginTop: 10,
              textAlign: 'center',
              fontWeight: 700,
              lineHeight: '16px',
            }}
          >
            Cancel / Postpone
          </Text>
        </ButtonAction>
      ) : null}
      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={history}
      />
    </div>
  );
};

export default HeaderButtons;
