import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';

export const query = gql`
  query Me {
    me {
      id
      track {
        id
        name
        logo
        cityAndState
      }
      series {
        id
        name
        logo
      }
      first_name
      middle_name
      address
      last_name
      email
      cellphone
      country_code
      calling_code
      capabilities {
        account_id
        events
        guests
        add_guests
        messages
        add_promos
        promos
        credits
        issue_credits
        transactions
        issue_refunds
        registrations
        customers
        memberships
        staff
        add_staff
        stripe
        scan_tickets
        check_tickets
        create_messages
        edit_registrations
        check_in_guests
        edit_memberships
        edit_billing
      }
      promoters {
        cellphone
        name
      }
      accounts {
        id
        business_dba
        card {
          last4
          brand
        }
      }
    }
  }
`;

export default graphql(query, {
  options: () => {
    return {
      variables: {},
      fetchPolicy: 'cache-and-network',
    };
  },
});
